<template>
  <div class="default-modal">
    <el-dialog
      @close="close"
      width="45%"
      top="15vh"
      v-model="exportDialogVisisble"
      title="Physicians Report"
    >
      <div v-loading="exportPhysicians">
        <div class="export-dialog-header">
          <div class="export-header-part"><h3>PHYSICIANS REPORT</h3></div>

          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="close"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="selected-string">
          <h3 v-if="selected_search_string">
            Search Physician :
            <span class="string-red">{{ selected_search_string }}</span>
          </h3>
          <h3 v-if="selected_search_fax">
            Search Fax : <span class="value">{{ selected_search_fax }}</span>
          </h3>
          <h3 v-if="selected_search_phone">
            Search Phone :
            <span class="string-red">{{ selected_search_phone }}</span>
          </h3>
          <h3 v-if="selected_search_specializations">
            Search Specializations :
            <span class="string-red">{{
              selected_search_specializations
            }}</span>
          </h3>
          <h3 v-if="selected_search_status.length">
            Selected Status:<span class="value">{{
              selected_search_status
            }}</span>
          </h3>
        </div>
        <div class="export-include-select-field">
          <h4 class="check-all-sub-title">DATA INCLUDES :</h4>

          <el-checkbox
            class="search-checkbox"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >Check All</el-checkbox
          >
        </div>
        <div class="dialog-body">
          <div class="export-popup-body mt-0" v-loading="loading">
            <el-checkbox-group v-model="activeFieldList" @change="isCheckAll">
              <el-row>
                <el-col :span="24">
                  <ul class="select-fields-list">
                    <li
                      class="search-checkbox"
                      v-for="(item, index) in exportPhysiciansExcelFields"
                      :key="index"
                    >
                      <el-checkbox :label="item.value">{{
                        item.label
                      }}</el-checkbox>
                    </li>
                  </ul>
                </el-col>
              </el-row>
            </el-checkbox-group>
          </div>
        </div>
        <div class="dialog-footer export-footer w-95">
          <el-button class="cancel-button" @click="close">CANCEL</el-button>
          <el-button class="blue-save-button" @click="downloadPhysicians()">
            DOWNLOAD
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import caseDataHelper from "@/mixins/caseDataHelper";
import AppHelper from "@/mixins/AppHelper";
import exportExcelHelper from "@/mixins/exportExcelHelper";

import FilterHelper from "@/mixins/FilterHelper";
import { mapGetters } from "vuex";
import moment from "moment";

import caseConstants from "@/config/constants/caseConstants";
export default {
  mixins: [
    UserPermissionsHelper,
    exportExcelHelper,
    caseDataHelper,
    AppHelper,
    FilterHelper,
  ],
  props: [
    "selected_search_string",
    "selected_search_phone",
    "selected_search_fax",
    "selected_search_specializations",
    "selected_search_status",
  ],
  computed: {
    ...mapGetters("orders", ["getDownloadOrderDataStatus"]),
    ...mapGetters("physicians", ["getAllPhysicians"]),
    ...mapGetters("hospitals", ["getAllHospitals"]),

    getCaseTypeMap() {
      return (status) => this.caseTypeMap[status];
    },
    getStatusMap() {
      return (status) => {
        return (status || []).map((e) => this.statusMap[e]);
      };
    },
  },
  data() {
    return {
      exportPhysicians: false,
      exportDialogVisisble: true,
      fieldList: [],
      activeFieldList: [],
      checkAll: true,
      loading: false,
      date_type: "collection_date",
      status: "",
      statusMap: caseConstants.STATUS_TYPE_MAP,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
    };
  },
  mounted() {
    this.loadFieldsList();
    this.status = this.search_case_status;
  },
  methods: {
    isCheckAll() {
      if (
        this.activeFieldList &&
        this.exportPhysiciansExcelFields &&
        this.activeFieldList.length &&
        this.exportPhysiciansExcelFields.length &&
        this.activeFieldList.length == this.exportPhysiciansExcelFields.length
      ) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
    async downloadPhysicians() {
      try {
        this.exportPhysicians = true;
        if (this.activeFieldList.length) {
          let params = {
            include: this.activeFieldList,
          };
          if (this.selected_search_string != "") {
            params.search_string = this.selected_search_string;
          }
          if (this.selected_search_fax != "") {
            params.fax = this.selected_search_fax;
          }
          if (this.selected_search_phone != "") {
            params.phone = this.selected_search_phone;
          }
          if (this.selected_search_specializations != "") {
            params.specializations = this.selected_search_specializations;
          }
          if (this.selected_search_status != "") {
            params.status = this.selected_search_status;
          }
          await this.$store.dispatch(
            "physicians/downloadPhysiciansData",
            params
          );
          this.exportPhysicians = false;
          this.close();
        } else {
          this.$message({
            message: "Please select the data fields.",
            type: "warning",
          });
        }
      } catch (err) {
        this.exportPhysicians = false;
        console.log(err);
      }
    },
    loadFieldsList() {
      this.exportPhysiciansExcelFields.forEach((obj) => {
        this.fieldList.push(obj.value);
      });
      this.activeFieldList = this.fieldList;
    },

    close() {
      this.$emit("close");
    },
    handleCheckAllChange(val) {
      this.activeFieldList = val ? this.fieldList : [];
    },
  },
};
</script>
