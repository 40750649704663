export default {
  data() {
    return {
      exportExtraCasesExcelFields: [
        {
          label: "Accession ID",
          value: "accession_id",
        },
        {
          label: "Bill To",
          value: "billing_type",
        },
        {
          label: "Case Type",
          value: "case_types",
        },
        {
          label: "Date of Service(DOS)",
          value: "collection_date",
        },
        {
          label: "Territory Maanager",
          value: "hospital_marketers",
        },
        {
          label: "Insurance Name",
          value: "billing_info.payor",
        },
        {
          label: "Ordering Facility",
          value: "hospital.name",
        },
        {
          label: "Ordering Physician",
          value: "ordering_physician.name",
        },
        {
          label: "Ordering Physician NPI",
          value: "ordering_physician.npi_code",
        },
        {
          label: "Patient First Name",
          value: "patient_info.first_name",
        },
        {
          label: "Patient Last Name",
          value: "patient_info.last_name",
        },
        {
          label: "Patient DOB",
          value: "patient_info.date_of_birth",
        },
        {
          label: "Policy No",
          value: "billing_info.policy_id",
        },
        {
          label: "Received Date",
          value: "received_date",
        },
        {
          label: "Status",
          value: "status",
        },
        {
          label: "Test Panels",
          value: "tests_panels",
        },
        {
          label: "Amendment Date",
          value: "amendement_details.amendment_time",
        },
        {
          label: "Amendment Reason",
          value: "amendement_details.amendment_revision_notes",
        },
        {
          label: "AR Notes",
          value: "ar_notes",
        },
        {
          label: "Assigned To",
          value: "test_performed_by.user",
        },
        {
          label: "Attachments Count",
          value: "attachments",
        },
        {
          label: "Attachments Exist",
          value: "attachments_count",
        },
        {
          label: "Billing Status",
          value: "billing_status",
        },
        {
          label: "Billing Sub Status",
          value: "billing_sub_status",
        },
        {
          label: "CP Notes",
          value: "billing_status_notes",
        },
        {
          label: 'Billing Assigne',
          value: 'billing_representative',
        },
        {
          label: "Coding Notes",
          value: "billing_notes",
        },
        {
          label: "Covid Status",
          value: "covid_status",
        },
        {
          label: "CPT Codes",
          value: "cpt_coding_details",
        },
        {
          label: "Created Date",
          value: "created_at",
        },
        {
          label: "EMR ID",
          value: "emr_reference_id",
        },
        {
          label: "Finalized Date",
          value: "finalized_date",
        },
        {
          label: "ICD Codes",
          value: "icd_codes",
        },
        {
          label: "Individual Remaining Deductible Amount",
          value:
            "billing_info.insurance.primary_insurance.individual_deductible_remaining",
        },
        {
          label: "Insurance Verified",
          value: "insurance_eligibility_verified"
        },
        {
          label: "Insurance Status",
          value: "insurance_eligibility_verified_details.foundCoverages[0].additionalDetail"
        },
        {
          label:"Insurance Carrier Code",
          value:"billing_info.insurance_carrier_code"
        },
        {
          label: "Login User",
          value: "user",
        },
        {
          label: "Ordering Facility Branch",
          value: "hospital_branch.display_name",
        },
        {
          label: "Order ID",
          value: "order_id",
        },
        {
          label: "Ordering Facility Phone",
          value: "hospital.phone",
        },
        {
          label: "Ordering Facility State",
          value: "hospital.state",
        },
        {
          label: "Patient Middle Name",
          value: "patient_info.middle_name",
        },
        {
          label: "Patient Gender",
          value: "patient_info.gender",
        },
        {
          label: "Patient EMR",
          value: "patient_info.emr",
        },
        {
          label: "Patient Phone",
          value: "patient_info.phone",
        },
        {
          label: "Patient Email",
          value: "patient_info.email",
        },
        {
          label: "Patient Address",
          value: "patient_info.address",
        },
        {
          label: "Plan Name",
          value: "billing_info.insurance.primary_insurance.waveland_plan_name",
        },
        {
          label: "Pregnancy",
          value: "patient_info.pregnant",
        },
        {
          label: "Report Result Status",
          value: "report_result_status",
        },
        {
          label: "Specimen Collector",
          value: "specimen_collector",
        },
        {
          label: "Sendout",
          value: "is_sendout",
        },
        {
          label: "Send Out Lab",
          value: "sendout.name"
        },
        {
          label: "TAT",
          value: "TAT",
        },
      ],
      exportCasesExcelFields: [
        {
          label: "Accession ID",
          value: "accession_id",
        },
        {
          label: "Bill To",
          value: "billing_type",
        },
        {
          label: "Case Type",
          value: "case_types",
        },
        {
          label: "Date of Service(DOS)",
          value: "collection_date",
        },
        {
          label: "Insurance Name",
          value: "billing_info.payor",
        },
        {
          label: "Ordering Facility",
          value: "hospital.name",
        },
        {
          label: "Ordering Physician",
          value: "ordering_physician.name",
        },
        {
          label: "Ordering Physician NPI",
          value: "ordering_physician.npi_code",
        },
        {
          label: "Patient First Name",
          value: "patient_info.first_name",
        },
        {
          label: "Patient Last Name",
          value: "patient_info.last_name",
        },
        {
          label: "Patient DOB",
          value: "patient_info.date_of_birth",
        },
        {
          label: "Policy No",
          value: "billing_info.policy_id",
        },
        {
          label: "Received Date",
          value: "received_date",
        },
        {
          label: "Status",
          value: "status",
        },
        {
          label: "Amendment Date",
          value: "amendement_details.amendment_time",
        },
        {
          label: "Amendment Reason",
          value: "amendement_details.amendment_revision_notes",
        },
        {
          label: "Covid Status",
          value: "covid_status",
        },
        {
          label: "Created Date",
          value: "created_at",
        },
        {
          label: "Finalized Date",
          value: "finalized_date",
        },
        {
          label: "ICD Codes",
          value: "icd_codes",
        },
        {
          label: "Login User",
          value: "user",
        },
        {
          label: "Ordering Facility Branch",
          value: "hospital_branch.display_name",
        },
        {
          label: "Order ID",
          value: "order_id",
        },
        {
          label: "Ordering Facility Phone",
          value: "hospital.phone",
        },
        {
          label: "Patient Middle Name",
          value: "patient_info.middle_name",
        },

        {
          label: "Patient Gender",
          value: "patient_info.gender",
        },
        {
          label: "Patient EMR",
          value: "patient_info.emr",
        },
        {
          label: "Patient Phone",
          value: "patient_info.phone",
        },
        {
          label: "Patient Email",
          value: "patient_info.email",
        },
        {
          label: "Patient Address",
          value: "patient_info.address",
        },
        {
          label: "Pregnancy",
          value: "patient_info.pregnant",
        },
        {
          label: "Specimen Collector",
          value: "specimen_collector",
        },
        {
          label: "TAT",
          value: "TAT",
        },
      ],
      exportPhysiciansExcelFields: [
        {
          label: "NPI Number",
          value: "npi_code",
        },
        {
          label: "Name",
          value: "name",
        },
        {
          label: "Specializations",
          value: "specializations",
        },
        {
          label: "Phone",
          value: "phone",
        },
        {
          label: "Email",
          value: "email",
        },
        {
          label: "Address",
          value: "address",
        },
        {
          label: "Ordering Facilities",
          value: "hospitals",
        },
        {
          label: 'PECOS Status',
          value: 'pecos_status'
        }
      ],
      exportHospitalsExcelFields: [
        {
          label: "Ordering Facility Name",
          value: "name",
        },
        {
          label: "Ordering Facility Code",
          value: "code",
        },
        {
          label: "Ordering Facility Phone",
          value: "phone",
        },
        {
          label: "Ordering Facility Email",
          value: "email",
        },
        {
          label: "Ordering Facility Fax",
          value: "fax",
        },
        {
          label: "Ordering Facility Address",
          value: "address",
        },
        {
          label: "Ordering Facility NPI",
          value: "npi",
        },
        // {
        //   label: "No of Territory Manager",
        //   value: "marketers_count",
        // },
      ],
      exportSendOutsExcelFields: [
        {
          label: "Patient First Name",
          value: "patient_info.first_name",
        },
        {
          label: "Patient Last Name",
          value: "patient_info.last_name",
        },
        {
          label: "Patient DOB",
          value: "patient_info.date_of_birth",
        },
        {
          label: "Patient Gender",
          value: "patient_info.gender",
        },
        {
          label: "Patient Phone",
          value: "patient_info.phone",
        },
        {
          label: "Patient Address",
          value: "patient_info.address",
        },
        {
          label: "Case Type",
          value: "case_types",
        },
        {
          label: "Bill To",
          value: "billing_type",
        },
        {
          label: "Ordering Facility",
          value: "hospital.name",
        },
        {
          label: "Ordering Facility Phone",
          value: "hospital.phone",
        },
        {
          label: "Ordering Physician",
          value: "ordering_physician.name",
        },
        {
          label: "Ordering Physician NPI",
          value: "ordering_physician.npi_code",
        },
        {
          label: "Collection Date",
          value: "collection_date",
        },
        {
          label: "Received Date ",
          value: "received_date",
        },
      ],
      defaultExportCasesExcelFields: [
        {
          label: "Accession ID",
          value: "accession_id",
        },
        {
          label: "Case Type",
          value: "case_types",
        },
        {
          label: "Bill To",
          value: "billing_type",
        },
        {
          label: "Patient First Name",
          value: "patient_info.first_name",
        },

        {
          label: "Patient Last Name",
          value: "patient_info.last_name",
        },
        {
          label: "Patient DOB",
          value: "patient_info.date_of_birth",
        },

        {
          label: "Ordering Facility",
          value: "hospital.name",
        },

        {
          label: "Ordering Physician",
          value: "ordering_physician.name",
        },
        {
          label: "Ordering Physician NPI",
          value: "ordering_physician.npi_code",
        },
        {
          label: "Date of Service(DOS)",
          value: "collection_date",
        },
        {
          label: "Insurance Name",
          value: "billing_info.payor",
        },
        {
          label: "Policy No",
          value: "billing_info.policy_id",
        },
        {
          label: "Received Date",
          value: "received_date",
        },
        {
          label: "Status",
          value: "status",
        },
        {
          label: "Test Panels",
          value: "tests_panels",
        },
        {
          label: "Territory Maanager",
          value: "hospital_marketers",
        },
      ],
      groupBasedFields: {
        billingUsers: [
          "accession_id",
          "billing_type",
          "case_types",
          "collection_date",
          "hospital_marketers",
          "billing_info.payor",
          "hospital.name",
          "ordering_physician.name",
          "ordering_physician.npi_code",
          "patient_info.first_name",
          "patient_info.last_name",
          "patient_info.date_of_birth",
          "billing_info.policy_id",
          "received_date",
          "status",
          "tests_panels",
          "amendement_details.amendment_time",
          "amendement_details.amendment_revision_notes",
          "ar_notes",
          "test_performed_by.user",
          "attachments",
          "attachments_count",
          "billing_status",
          "billing_sub_status",
          "billing_status_notes",
          "billing_representative",
          "billing_notes",
          "covid_status",
          "cpt_coding_details",
          "created_at",
          "emr_reference_id",
          "finalized_date",
          "icd_codes",
          "billing_info.insurance.primary_insurance.individual_deductible_remaining",
          "user",
          "hospital_branch.display_name",
          "order_id",
          "hospital.phone",
          "hospital.state",
          "patient_info.middle_name",
          "patient_info.gender",
          "patient_info.emr",
          "patient_info.phone",
          "patient_info.email",
          "patient_info.address",
          "billing_info.insurance.primary_insurance.waveland_plan_name",
          "patient_info.pregnant",
          "report_result_status",
          "specimen_collector",
          "is_sendout",
          "TAT",
          "sendout.name",
          "insurance_eligibility_verified",
          "insurance_eligibility_verified_details.foundCoverages[0].additionalDetail",
          "billing_info.insurance_carrier_code"
          // ... other fields for billing users
        ],
        labUsers: [
          "accession_id",
          "billing_type",
          "case_types",
          "collection_date",
          "billing_info.payor",
          "hospital.name",
          "ordering_physician.name",
          "ordering_physician.npi_code",
          "patient_info.first_name",
          "patient_info.last_name",
          "patient_info.date_of_birth",
          "billing_info.policy_id",
          "received_date",
          "status",
          "tests_panels",
          "amendement_details.amendment_time",
          "amendement_details.amendment_revision_notes",
          "attachments",
          "attachments_count",
          "covid_status",
          "created_at",
          "emr_reference_id",
          "finalized_date",
          "icd_codes",
          "order_id",
          "patient_info.middle_name",
          "patient_info.gender",
          "patient_info.phone",
          "patient_info.address",
          "report_result_status",
          "is_sendout",
          "TAT",
          // ... other fields for lab users
        ],
        facilityUsers: [
          "accession_id",
          "billing_type",
          "case_types",
          "collection_date",
          "billing_info.payor",
          "hospital.name",
          "ordering_physician.name",
          "ordering_physician.npi_code",
          "patient_info.first_name",
          "patient_info.last_name",
          "patient_info.date_of_birth",
          "billing_info.policy_id",
          "received_date",
          "status",
          "tests_panels",
          "amendement_details.amendment_time",
          "amendement_details.amendment_revision_notes",
          "covid_status",
          "created_at",
          "emr_reference_id",
          "finalized_date",
          "hospital_branch.display_name",
          "order_id",
          "hospital.phone",
          "hospital.state",
          "patient_info.middle_name",
          "patient_info.gender",
          "patient_info.emr",
          "patient_info.phone",
          "patient_info.email",
          "patient_info.address",
          "billing_info.insurance.primary_insurance.waveland_plan_name",
          "patient_info.pregnant",
          "report_result_status",
          "TAT",
        ],

      },
    }
  },
}
